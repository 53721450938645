import React from "react"
import { graphql } from "gatsby"
import GatsImg from "gatsby-image"
import Headermenu from "../containers/RealestatePage1/HeaderMenuBS"
import Footer from "../containers/RealestatePage1/Footer"
import GlobalStyle from "../containers/RealestatePage1/Common/global-styles"
import { Container } from "react-bootstrap"
import Fonts from "../containers/RealestatePage1/Common/fonts"
import "../components/layout.css"
import SEO from "../components/seo"
import {
  contentWrapper,
  content,
  image,
} from "../containers/RealestatePage1/Common/markdownStyle.module.css"

function concierge({ data }) {
  console.log(data)
  const { html } = data.markdownRemark

  return (
    <>
      <Fonts />
      <GlobalStyle />
      <main>
        <SEO
          title="Concierge"
          description="Let us assist you with all your needs"
        />
        <Headermenu home={false} />

        <div className={contentWrapper} id="conciergeSection">
          <article>
            <Container>
              <div className={content}>
                <div dangerouslySetInnerHTML={{ __html: html }} />
              </div>
            </Container>
          </article>
        </div>
      </main>
      <Footer />
    </>
  )
}
export const query = graphql`
  query ConciergeQuery {
    markdownRemark(frontmatter: { title: { eq: "signature concierge" } }) {
      id
      html
    }
  }
`

export default concierge
